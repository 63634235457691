.title-result {
  text-align: center !important;
}

.team-statistiche {
  background: rgb(56, 0, 60);
  color: white;
  font-size: 28px;
  text-align: center;
}

.risultato {
  font-size: 28px;
  color: white;
  background: #e90052;
  text-align: center;
}

.card-text-result {
  margin-top: 0px;
}

.rowGoals {
  text-align: center;
  font-size: 15px;
}

.statistic {
  text-align: center;
  padding-bottom: 20px;
  font-size: 15px;
}
.progress {
  margin-top: 3px;
  background-color: rgb(217 133 100);
}

.progress-empty {
  background-color: #606060;
}

.progress-bar {
  background-color: #4b9aef !important;
}

.players {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

@media only screen and (max-width: 992px) {
  .risultato,
  .team-statistiche {
    font-size: 18px;
  }
  .rowGoals {
    font-size: 10px;
  }
  .players {
    display: none;
  }
  .statistic {
    text-align: center;
    padding-bottom: 20px;
    font-size: 13px;
  }
  .progress {
    height: 10px !important;
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: 400px) {
  .players {
    display: none;
  }
}
