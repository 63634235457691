.teamName {
    width: 60%
}

.table-style {
    padding: .05rem !important;
}

.girone__item__title {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 5px 5px 5px;
  }

@media only screen and (max-width: 992px) {
    .hidden {
      display: none !important;
    }
  }