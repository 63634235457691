.navbar {
  background: linear-gradient(
    90deg,
    rgb(26, 23, 23) 0%,
    #134865 60%
  ) !important;
  height: 80px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1.2rem !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
}

.img-logo {
  margin-top: -10px;
}

.navbar-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 70px !important;
  max-width: 1500px !important;
}

.navbar-logo {
  color: #fff !important;
  justify-self: start !important;
  cursor: pointer !important;
  text-decoration: none !important;
  font-size: 2rem !important;
  display: flex !important;
  align-items: center !important;
}

.fa-typo3 {
  margin-left: 0.5rem !important;
  font-size: 1.8rem !important;
}

.nav-menu {
  display: grid !important;
  grid-template-columns: repeat(7, auto) !important;
  grid-gap: 5px !important;
  list-style: none !important;
  text-align: center !important;
  width: 60vw !important;
  justify-content: end !important;
  margin-right: 2rem !important;
}



.dropdown,.dropdown-toggle {
  height: 80px !important;
  color: #fff !important;
  align-items: center !important;
  text-decoration: none !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 1.2rem !important;
  border: 0px !important;
  box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
  border-bottom: 0px !important;
}

.dropdown:hover {
  border-bottom: 4px solid #fff !important;
  transition: all 0.2s ease-out !important;
}

.nav-links {
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  text-decoration: none !important;
  padding: 0rem 0.5rem !important;
  height: 100% !important;
}

.nav-links:hover {
  border-bottom: 4px solid #fff !important;
  transition: all 0.2s ease-out !important;
}

.fa-bars {
  color: #fff !important;
}

.nav-links-mobile {
  display: none !important;
}

.menu-icon {
  display: none !important;
}

@media screen and (max-width: 1200px) {
  .NavbarItems {
    position: relative !important;
  }

  .nav-menu {
    display: flex !important;
    flex-direction: column !important;
    width: 50% !important;
    position: absolute !important;
    top: 80px !important;
    left: -100% !important;
    opacity: 1 !important;
    transition: all 0.5s ease !important;
  }

  .nav-menu.active {
    background: #242222 !important;
    left: 0 !important;
    opacity: 1 !important;
    transition: all 0.5s ease !important;
    z-index: 1 !important;
  }

  .nav-links {
    text-align: center !important;
    padding: 0.6rem !important;
    width: 100% !important;
    display: table !important;
  }

  .nav-links:hover {
    background-color: #fff !important;
    color: #242424 !important;
    border-radius: 0 !important;
  }

  .navbar-logo {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate(25%, 50%) !important;
  }

  .menu-icon {
    display: block !important;
    position: absolute !important;
    right: 0 !important;
    transform: translate(-100%, 60%) !important;
    font-size: 1.8rem !important;
    cursor: pointer !important;
    top: -5px !important;
  }

  .fa-times {
    color: #fff !important;
    font-size: 2rem !important;
  }

  .nav-links-mobile {
    display: block !important;
    text-align: center !important;
    border-radius: 4px !important;
    width: 70% !important;
    text-decoration: none !important;
    font-size: 1.5rem !important;
    background-color: transparent !important;
    color: #fff !important;
    padding: 14px 20px !important;
    border: 1px solid #fff !important;
    transition: all 0.3s ease-out !important;
    margin-left: 15% !important;
    margin-top: 10px !important;
  }

  .nav-links-mobile:hover {
    background: #fff !important;
    color: #23406b !important;
    transition: 250ms !important;
  }

  .img-logo {
    height: 30px !important;
    margin-top: 8px !important;
  }

  .dropdown:hover {
    border-bottom: 0px !important;
  }

  .dropdown,.dropdown-toggle {
    height: 47.99px !important;
  }
}
