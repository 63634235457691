* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.card {
  border: 0px !important;
  margin-bottom: 30px;
}

.card-body {
  padding: 0 !important;
}

.card-header {
  background-color: #0b3348 !important;
  color: #fff !important;
  text-align: center;
  font-size: 20px;
  padding: 0.75rem 0.75rem;
  /* border-radius: 10px !important; */
  padding: 5px 0px 0px 0px !important;
}

.card-header-empty {
  background-color: white !important;
  color: black !important;
  text-align: center;
  font-size: 20px;
  padding: 0.75rem 0.75rem;
  /* border-radius: 10px !important; */
  padding: 5px 0px 0px 0px !important;
}

.card-text {
  margin-top: 0px !important;
}

.table {
  text-align: center;
}

.information-card {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: center;
  padding: 0px !important;
}

.fa,
.fas {
  padding-right: 5px !important;
}

.fab,
.far {
  padding-right: 5px !important;
}

.page-title-2 {
  color: rgb(0, 0, 0);
  width: 50%;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.page-title {
  background: linear-gradient(180deg, #134865 0%, rgb(26, 23, 23) 100%);
  color: #fff;
  width: 100%;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.marker {
  font-size: 20px;
  color: red;
}

.form-style {
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.error-message {
  font-size: 15px;
  color: red;
}

.btn-rovarecup {
  padding: 8px 20px !important;
  border-radius: 2px !important;
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
}

.btn-rovarecup--primary {
  background-color: white;
  color: #242424 !important;
  border: 1px solid var(--primary) !important;
}

.btn-rovarecup--outline {
  background-color: transparent !important;
  color: #fff !important;
  padding: 8px 20px !important;
  border: 1px solid var(--primary) !important;
  transition: all 0.3s ease-out !important;
}

.btn-rovarecup--medium {
  padding: 8px 20px !important;
  font-size: 18px !important;
}

.btn-rovarecup--large {
  padding: 12px 26px !important;
  font-size: 20px !important;
}

.btn-rovarecup--large:hover,
.btn-rovarecup--medium:hover {
  transition: all 0.3s ease-out !important;
  background: #fff !important;
  color: #242424 !important;
  transition: 250ms !important;
}

.file {
  margin-right: 10px;
}

.edit {
  color: #17a2b8 !important;
}

.delete {
  color: red;
}

.manage {
  color: green !important;
}

.edit, .delete, .manage {
  background-color: white !important;
  border-color: white !important;
  padding: 0% !important;
}

.icon2 {
  width: auto;
  height: 20px;
  margin-top: -4px !important;
}

@media only screen and (min-width: 812px) {
  .icon2 {
    width: auto;
    height: 20px;
    margin-top: -4px !important;
  }
}

time {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important
}