.match__item__info {
  padding: 10px 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.match__item__title {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  display: inline;
}

@media only screen and (max-width: 1024px) {
  .match__item__info {
    width: 100%;
    padding: 5px 0px 0px
  }
  .match__item__title {
    width: 100%;
    font-size: 15px;
  }
}