.table td, .table th {
  border-top: 0px; 
}

.team {
  text-align: left;
  padding-right: 10px !important;
  border-right: 2px solid #dee2e6 !important;
  font-size: 16px
}

.hour {
  text-align: left;
  padding-left: 0px !important;
  color: black !important;
  font-size: 16px
}

.result {
  text-align: left;
  font-size: 16px
}

.border-card {
  /* border-top: 1px solid #dee2e6; */
  border-bottom: 1px solid #dee2e6;
}

.table {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}
.table-responsive{
  padding-top: 5px !important;
  margin-bottom: -15px;
  margin-right: 20px !important;
}

@media only screen and (max-width: 992px) {
  .team {
    text-align: left;
    font-size: 13px;
  }
  .hour, .result {
    font-size: 13px;
  }
}

.p-result {
  margin-bottom: 0 !important;
}

.win-icon {
  padding-left: 5px;
}