.title {
    background: linear-gradient(90deg,rgb(26, 23, 23) 0%, #23406b 100%);
    color: #fff;
    border-radius: 10px;
  }

.videos {
  padding: 4rem;
  /* background-image: url("../../src/assets/images/img-8.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: relative;
  height: 90vh;
}