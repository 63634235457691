
  .card_title {
    padding-top: 30px;
    padding-bottom: 20px;
  }

.cards_info_title {
  display: flex !important;
    flex-flow: column !important;
    width: 100% !important;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17) !important;
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)) !important;
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)) !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    text-decoration: none !important;
  color: white;
  background: linear-gradient(180deg, #134865 0%, rgb(26, 23, 23) 100%);
}

.cards_item_text_title {
  margin-top: 8px;
}